/* eslint-disable max-len */
import { PageRendererProps } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import styled from 'styled-components'

import BannerImage from '../../assets/static/banner/banner_taipei.jpg'
import EnvironmentImg1 from '../../assets/static/clinic/taipei_environment1.jpg'
import EnvironmentImg2 from '../../assets/static/clinic/taipei_environment2.jpg'
import EnvironmentImg3 from '../../assets/static/clinic/taipei_environment3.jpg'
import EnvironmentImg4 from '../../assets/static/clinic/taipei_environment4.jpg'
import consultImage from '../../assets/static/consult/consult1.jpg'
import Button from '../../components/Button'
import { getClinicInfo } from '../../components/Clinic/getClinicInfo'
import { GridItem, GridLayout } from '../../components/Grid'
import ClinicLayout from '../../components/Layout/ClinicLayout'
import Seo from '../../components/Seo'
import { space } from '../../constants/length'
import { Hsinchu, Taichung, Taipei, TaipeiSecondary } from '../../constants/newColor'
import { Body, H2 } from '../../constants/newText'
import { viewport } from '../../constants/viewport'
import { Location } from '../../types/types'
import CaseSection from './components/CaseSection'
import Consult from './components/Consult'
import DoctorSection from './components/Doctor'
import GoogleMap from './components/GoogleMap'
import LogoInfo from './components/LogoInfo'
import OtherClinic from './components/OtherClinic'
import TimetableSection from './components/Timetable'
import { timetableData } from './components/Timetable/staticData'

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ContentTitle = styled.h2`
${H2}
color: ${TaipeiSecondary};
margin-bottom: ${space.l}px;
&:after {
  content: '';
  display: block;
  width: 4rem;
  height: 1px;
  margin: ${space.s}px auto;
  background-color: ${TaipeiSecondary};
}
`

const Img = styled.img`
  width: 100%;
  object-fit: cover;
  @media (min-width: ${viewport.tablet}px) {
    height: 180px;
  }
  @media (max-width: ${viewport.tablet - 1}px) {
    height: 156px;
  }
`

const Info = styled.div`
  ${Body}
  display: flex;
  flex-direction: column;
  align-items: center;
`

const MoreCase = styled.a`
  display: inline-block;
  margin-top: ${space.xl}px;
`

const ConsultWrapper = styled.div`
  width: 100%;
  position: relative;
  @media (min-width: ${viewport.desktop}px) {
    height: 406px;
  }
  @media (max-width: ${viewport.desktop - 1}px) and (min-width: ${viewport.tablet}px) {
    height: 294px;
  }
  @media (max-width: ${viewport.tablet - 1}px) {
    height: 200px;
  }
`

const ClinicTaipei = (props: PageRendererProps) => {
  const { formatMessage } = useIntl()
  const routes = [
    {
      label: formatMessage({ id: 'breadcrumb.index' }),
      path: '/'
    },
    {
      label: formatMessage({ id: 'breadcrumb.clinic' }),
      path: '/clinic'
    },
    {
      label: formatMessage({ id: 'clinic.taipei' }),
      path: '/clinic/taipei'
    }
  ]

  return (
    <ClinicLayout imageUrl={BannerImage} pageBannerInfo={formatMessage({ id: 'banner.taipei' })} routes={routes} {...props}>
      <Seo
        title='【台北陶瓷貼片推薦】日亞美牙醫診所：隱形矯正、矯正專科、SOV特約'
        metaDescription='台北東區日亞美牙醫診所，位於捷運忠孝敦化6號出口，擁有台大、北醫多位矯正專科醫師，提供隱形牙套、戴蒙矯正、陶瓷貼片、人工植牙等牙科治療，「對待您如同家人朋友」是日亞美服務的堅持。'
      />
      <LogoInfo location={Location.Taipei} />
      <Content>
        <GridLayout templateColumns={['100%', '1fr 1fr']} templateAreas={[`'imgSection1' 'info' 'imgSection2'`, `'info info''imgSection1 imgSection2'`]} rowGap={['24px', '120px']}>
          <GridItem area='imgSection1'>
            <GridLayout templateColumns={['3fr 7fr']}>
              <Img src={EnvironmentImg1} />
              <Img src={EnvironmentImg2} />
            </GridLayout>
          </GridItem>
          <GridItem area='info'>
            <ContentTitle>關於我們</ContentTitle>
            <Info>
              <p>はじめまして、どうぞよろしく</p>
              <p>『傳承日本服務精神，結合台灣美學齒科』</p>
              <p>
                本院導入牙科數位化『3shape口內掃描儀』，以及MIT第一品牌『SOV數位隱形矯正系統』，並廣邀各專科權威，只為了提供給您最專業、舒適的醫療環境。此外，本院特別設立『美齒專科』門診，結合隱形矯正、微笑瓷牙、牙周美容、牙齒美白等，由各專科醫師與您攜手打造專屬笑容。『對待您如同家人朋友』是日亞美服務的堅持，希望帶給您全新的看診體驗。
              </p>
            </Info>
          </GridItem>
          <GridItem area='imgSection2'>
            <GridLayout templateColumns={['7fr 3fr']}>
              <Img src={EnvironmentImg3} />
              <Img src={EnvironmentImg4} />
            </GridLayout>
          </GridItem>
        </GridLayout>
      </Content>
      <Content>
        <ContentTitle>專業團隊</ContentTitle>
        <DoctorSection page={Location.Taipei} />
      </Content>
      <Content>
        <ContentTitle>矯正心得分享</ContentTitle>
        <CaseSection location={[Location.Taipei]} />
        <MoreCase href={`/case?location=${Location.Taipei}`}>
          <Button label='MORE &#9658;' type='secondary' />
        </MoreCase>
      </Content>
      <Content>
        <ConsultWrapper>
          <Consult color={Taipei} imageUrl={consultImage} />
        </ConsultWrapper>
      </Content>
      <Content>
        <ContentTitle>門診資訊</ContentTitle>
        <TimetableSection
          data={timetableData}
          primaryColor={Taipei}
          secondaryColor={TaipeiSecondary}
          oddColor={Taichung}
          evenColor={Hsinchu}
          tel={getClinicInfo({ location: Location.Taipei, value: 'tel' })}
          line={getClinicInfo({ location: Location.Taipei, value: 'socialMedia_line' })}
        />
      </Content>
      <Content id='location'>
        <GoogleMap lat={25.04119} lng={121.5493} title='sunlight_taipei' add={getClinicInfo({ location: Location.Taipei, value: 'address' })} />
      </Content>
      <Content>
        <ContentTitle>其他診所</ContentTitle>
        <OtherClinic />
      </Content>
    </ClinicLayout>
  )
}

export default ClinicTaipei
